<template lang="pug">
.form-modal(v-if="showFormManager.lumiraSettings" @mousedown="cancel")
  .content.wheel-settings.wider(@mousedown.stop="")
    .header.om-bottom-divider.pb-4
      .button.om-close(@click="cancel" style="position: absolute; top: 0; right: 0") &times;
      .om-row.header-inner
        .om-col.om-col-7
          .om-title-field {{ $t('wheelSettings') }}
    table
      thead
        tr.om-modal-tr.om-modal-tr-title
          th.om-modal-wheel-th(
            v-for="key in getExtendedTitle"
            style="text-align: left"
            :class="{ 'w-15': key === 'couponNextPage' }"
          ) {{ $t(key) }}
      tbody.om-table-scroll.om-table-scroll-wheel
        tr.om-modal-tr.om-modal-wheel-tr(v-for="(row, index) in options")
          td.om-modal-wheel-td
            input.simpleInput.wheelInput(
              :class="{ 'invalid-field': isAllZeroed && !index }"
              step="1"
              min="0"
              v-model="options[index].weigth"
              @input="onWeightInput($event, index)"
            )
          td.om-modal-wheel-td
            div {{ probability(row.weigth) }}%
          td.om-modal-wheel-td
            input(:class="classes(index, 'title')" v-model="options[index].title")
          td.om-modal-wheel-td
            input(
              :class="classes(index, 'couponCode')"
              :disabled="disabled(index)"
              v-model="options[index].couponCode"
            )
          td.om-modal-wheel-td.w-15
            om-dropdown-input(
              label=""
              :items="luckyWheelAction"
              :i18n="false"
              v-model="options[index].couponNextPage"
            )
    .footer.d-flex.justify-content-between(style="position: relative")
      .col-auto.d-flex
        om-button(secondary @click="toggleOptionsCount") {{ optionsToggleTitle }}
      .col-auto.d-flex.align-items-center
        om-button.mr-4(ghost secondary @click="cancel") {{ $t('cancel') }}
        om-button(primary @click="save") {{ $t('save') }}
</template>

<script>
  import { mapState, mapMutations, mapGetters } from 'vuex';
  import weighting from '@/editor/mixins/weighting';
  import { get as _get } from 'lodash-es';

  const frameStore = () => {
    const workspace = document.getElementById('workspaceFrame');
    return workspace.contentWindow.om.store;
  };

  const _clone = (v) => JSON.parse(JSON.stringify(v));

  export default {
    mixins: [weighting],

    data() {
      return {
        titleOptions: ['weightingFactor', 'chanceToWin', 'title', 'couponCode'],
        selectedField: null,
        percent: 1,
        options: [],
      };
    },

    computed: {
      ...mapState(['selectedPage', 'selectedElement', 'showFormManager', 'wheelOptions']),
      ...mapGetters(['hasAccountFeature']),
      getExtendedTitle() {
        return [...this.titleOptions, 'couponNextPage'];
      },
      luckyWheelAction() {
        const pages = frameStore().getters.pages || [];
        const actions = [{ key: this.$t(`defaultPage`), value: null }];
        for (let i = 0; i < pages.length; i++) {
          const page = pages[i];

          if (page.uid === this.selectedPage.uid) {
            continue;
          }

          const key = _get(page, 'data.title') || i + 1;
          const option = this.$t('jumpToPage').concat(` ${key}`).concat(this.$t('jumpToPageAfter'));
          actions.push({ key: option, value: i + 1 });
        }
        return actions;
      },
      optionsCount() {
        return this.selectedElement?.data?.optionsCount ?? 12;
      },
      isLessOptions() {
        return this.optionsCount === 6;
      },
      optionsToggleTitle() {
        return this.isLessOptions
          ? this.$t('luckywheelPane.addPrizes')
          : this.$t('luckywheelPane.lessPrizes');
      },
      saveableOptions() {
        const clonedOptions = _clone(this.options);
        let wheelOptions = clonedOptions;

        if (this.isLessOptions) {
          wheelOptions = [...clonedOptions, ...this.$store.state.wheelOptions.slice(6)];
        }

        return wheelOptions;
      },
    },

    watch: {
      wheelOptions(n) {
        this.options = this.initSettingsWithCountOfSlices(n);
      },
      'selectedElement.data.optionsCount': {
        handler() {
          this.options = this.initSettingsWithCountOfSlices(this.wheelOptions);
        },
      },
    },

    mounted() {
      this.options = this.initSettingsWithCountOfSlices(this.wheelOptions);
    },

    methods: {
      ...mapMutations(['changeFormManagerVisibility']),

      initSettingsWithCountOfSlices(data) {
        const cloned = _clone(data);
        const slices = this.selectedElement?.data?.optionsCount ?? 12;
        return cloned.slice(0, slices);
      },

      cancel() {
        this.saveBtnClicked = false;
        this.hideFrom('cancel', 'lumiraSettings');
        this.options = _clone(this.wheelOptions);
      },

      onWeightInput(e, index) {
        const isInt = e.target.value.match(/\d+/g);
        const newValue = isInt ? isInt[0] : 0;
        this.options[index].weigth = parseInt(newValue, 10);
      },

      save() {
        this.saveBtnClicked = true;
        if (this.isActiveSave(true)) {
          this.hideFrom('save', 'lumiraSettings');
          this.$bus.$emit('setWorkspaceStateAttr', {
            attr: 'wheelOptions',
            stateAttr: 'template',
            value: this.saveableOptions,
          });
          this.$store.state.wheelOptions = this.saveableOptions;
        }
      },
      toggleOptionsCount() {
        this.selectedElement.data.optionsCount = this.isLessOptions ? 12 : 6;
      },
    },
  };
</script>

<style lang="sass" scoped>
  .form-modal
    .wider
      width: 56rem
  .jump-to
    min-width: 4rem
  .selected-custom-field td
    color: #ed5a29
  .invalid-field
    border-color: #ff9494
    background-color: #ff9494
  .disabled
    background: #e8eaec
</style>
